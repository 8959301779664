import { mdiAttachment, mdiInformationOutline, mdiPrinter } from "@mdi/js";
import Icon from "@mdi/react";
import axios from "axios";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../context_providers/auth/authProvider";
import DrawerWidget from "../../../context_providers/modal/drawer_widget";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ModalType,
  ModalAlignment,
} from "../../../context_providers/modal/modal_context";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import {
  fetchPut,
  fetchPost,
  fetchGet,
  fetchDelete,
  toQueryString,
} from "../../../service/api_client";
import DoneWidget from "../../../ui/new_components/common/done";
import { numberRegEx } from "../../../utils/constant";
import { UTCToLocalDate } from "../../../utils/date_util";
import { ACTION, MODULE, PaymentMode, file_dir } from "../../../utils/enums";
import { currencyFormat } from "../../../utils/orders_utils";
import LedgerSearchField from "../../accounts/ledgers/ledger_search_field";
import {
  accountTxnType,
  LedgerEntryModel,
} from "../../accounts/models/common_model";
import { account_group } from "../../accounts/models/ledger_group_model";
import { LedgerModel, tax_type } from "../../accounts/models/ledger_model";
import {
  currencies,
  default_mail_detail,
} from "../../accounts/models/party_model";
import { ledgerEntryDefaultValue } from "../../accounts/models/purchase_model";
import PartySearchField from "../../accounts/parties/party_search_field";
import WarehouseSearchField from "../../inventory/warehouse/warehouse_search_field";
import {
  AttachmentModel,
  auto_approval_amount,
  getFileType,
  PoItem,
  po_approval_status,
  po_default_value,
  po_status,
  PurchaseOrderModel,
  getAprrovalLevel,
} from "../model/purchase_order_model";
import AttachmentSection from "./components/attachment";
import PoItemRow from "./components/item_row";
import PoSnapSection from "./components/po_snaps";
import TaxEntryRow, { getItemTaxAmount } from "./components/tax_list";
import ProjectSearchField from "../projects/project_master/project_search_field";
import { ProcurementProjectModel } from "../projects/model/project_model";
import {
  PaymentRequestModel,
  payment_request_default,
} from "../model/payment_request_model";
import RequiredMark from "../../../ui/new_components/common/required_mark";
import PoCreateBottomSheetWidget from "./components/bottom_sheet";
import ModalDialog from "../../../ui/new_components/common/modal";
import { WAREHOUSE_TYPE } from "../../inventory/models/warehouse_model";

interface PurchaseOrderCreateScreenProps {}

const PurchaseOrderCreateScreen: FunctionComponent<
  PurchaseOrderCreateScreenProps
> = (props) => {
  const { user, isAuthorised } = useAuth();
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSucess] = useState(false);
  const [showCreatePaymentRequestModal, setShowCreatePaymentRequestModal] =
    useState(false);
  const { showToast } = useToast();
  const [addNewTaxId, setAddNewTaxId] = useState<number>();
  // const [oldTotal, setOldTotal] = useState(0);
  const [project, setProject] = useState<ProcurementProjectModel>();
  const [data, setData] = useState<PurchaseOrderModel>({
    ...po_default_value,
    shipping_profile: {
      ...default_mail_detail,
      contact_name: user?.first_name + " " + (user?.last_name || ""),
      contact_mobile: user?.mobile || "",
      contact_email: user?.email || "",
    },
  });
  const [prvData, setPrvData] = useState<PurchaseOrderModel>({
    ...po_default_value,
    shipping_profile: {
      ...default_mail_detail,
      contact_name: user?.first_name + " " + (user?.last_name || ""),
      contact_mobile: user?.mobile || "",
      contact_email: user?.email || "",
    },
  });

  const [addNewId, setAddNewId] = useState(Math.random() * 10000);

  const [disabled, setDisabled] = useState();

  useEffect(() => {
    if (disabled === undefined) {
      let tmpDisabled =
        data.id &&
        data.approval_status === po_approval_status.approved &&
        data.approved_by;

      if (tmpDisabled) {
        const authorised = isAuthorised({
          action: [ACTION.UPDATE, ACTION.CREATE, ACTION.READ],
          module: MODULE.purchase_order_approval,
        });
        if (authorised) {
          tmpDisabled = false;
        }
      }
      setDisabled(tmpDisabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const submit = async () => {
    if (!project) {
      return showToast({
        type: ToastType.error,
        text: "Select project budget",
      });
    }
    if (!data.party) {
      return showToast({
        type: ToastType.error,
        text: "Select party",
      });
    }
    if (!data.vendor_currency || !data.vendor_currency_to_inr) {
      return showToast({
        type: ToastType.error,
        text: "Select vendor currency and exchange rate",
      });
    }
    if (!data.warehouse.id) {
      return showToast({
        type: ToastType.error,
        text: "Select warehouse",
      });
    }
    if (!data.items.length) {
      return showToast({
        type: ToastType.error,
        text: "Select items",
      });
    }
    if (!data.shipping_method) {
      return showToast({
        type: ToastType.error,
        text: "select shipping method",
      });
    }
    if (!data.payment_mode) {
      return showToast({
        type: ToastType.error,
        text: "Select payment mode",
      });
    }
    if (!data.status) {
      return showToast({
        type: ToastType.error,
        text: "select status",
      });
    }

    const total = data.total * data.vendor_currency_to_inr;
    const bal =
      (project?.amount || 0) -
      ((project?.spent || 0) -
        (prvData.project_id === data.project_id ? prvData.total : 0));
    if (total > bal) {
      return showToast({
        type: ToastType.error,
        text: "Total amount should not exceed the project balance amount.",
      });
    }
    if (!window.confirm("Are you sure ?")) return;
    data.project_id = project?.id;
    data.project = project?.name;
    setLoading(true);
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    const res = data.id
      ? await fetchPut(url + "/" + data.id, formData, data, {
          "Content-Type": "multipart/form-data",
        })
      : await fetchPost(url, formData, {
          "Content-Type": "multipart/form-data",
        });
    if (res.success) {
      if (!data.id) setShowSucess(true);
      data.project_id = project?.id;
      data.project = project?.name;
      setData(res.data);
      setPrvData(res.data);
      showToast({ type: ToastType.success, text: res.message });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id) getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      if (!res.data.vendor_currency) {
        res.data.vendor_currency = currencies.rupee;
        res.data.vendor_currency_to_inr = 1;
      }
      setData({ ...res.data });
      setPrvData({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    data.subtotal = 0;
    data.items.forEach((item) => {
      data.subtotal += item.bill_unit_no * item.price;
    });

    const taxes = data.taxes.map((t, i) => {
      // if (data.entry_type === voucherEntryType.Invoice)
      if (t.ledger?.tax_detail?.type === tax_type.gst) {
        const tax = getItemTaxAmount({
          entry_type: "Invoice",
          items: data.items || [],
          ledgerTaxDetail: t.ledger!.tax_detail!,
          extras: data.extras,
        });
        console.log({ tax });

        t.amount = tax || 0;
      }
      return { ...t };
    });
    let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
    data.tax = tax;
    data.taxes = taxes;
    data.total =
      data.subtotal + (tax ?? 0) + (data.extra ?? 0) - (data.discount ?? 0);

    // // data.total = data.subtotal + data.tax + data.extra - data.discount;
    // // if (data.total <= auto_approval_amount) {

    // // }
    // // else

    // // if (data.total > oldTotal && data.total > auto_approval_amount) {
    // //   data.approval_required = true;
    // //   data.approval_status = po_approval_status.pending;
    // // } else {
    // //   data.approval_required = data.total > auto_approval_amount;
    // //   data.approval_status = po_approval_status.approved;
    // // }
    // if (data.total <= auto_approval_amount) {
    //   // no approval required
    //   data.approval_required = false;
    //   data.approval_status = po_approval_status.approved;
    // } else {
    //   // approval required
    //   if (prvData.approval_status === po_approval_status.approved) {
    //     // already approved
    //     if (data.total > prvData.total) {
    //       //new total > old total
    //       data.approval_required = true;
    //       data.approval_status = po_approval_status.pending;
    //     } else {
    //       data.approval_required = prvData.approval_required;
    //       data.approval_status = prvData.approval_status;
    //     }
    //   } else {
    //     // already not approved
    //     data.approval_required = prvData.approval_required;
    //     data.approval_status = prvData.approval_status;
    //   }
    // }

    data.approval_level = getAprrovalLevel(data.total);
    setData({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.items, data.extras]);
  useEffect(() => {
    data.total = data.subtotal + data.tax - data.discount + data.extra;
    // // if (data.total <= auto_approval_amount) {
    // //   data.approval_required = false;
    // //   data.approval_status = po_approval_status.approved;
    // // } else if (data.total > oldTotal) {
    // //   data.approval_required = true;
    // //   data.approval_status = po_approval_status.pending;
    // // }
    // if (data.total > oldTotal && data.total > auto_approval_amount) {
    //   data.approval_required = true;
    //   data.approval_status = po_approval_status.pending;
    // } else {
    //   data.approval_required = data.total > auto_approval_amount;
    //   data.approval_status = po_approval_status.approved;
    // }

    if (data.total <= auto_approval_amount) {
      // no approval required
      console.log(" no approval required");

      data.approval_required = false;
      data.approval_status = po_approval_status.approved;
    } else {
      // approval required
      console.log("approval required");

      if (prvData.approval_status === po_approval_status.approved) {
        // already approved
        console.log("already approved");

        if (data.total > prvData.total) {
          //new total > old total
          console.log("new total > old total");

          data.approval_required = true;
          data.approval_status = po_approval_status.pending;
        } else {
          data.approval_required = prvData.approval_required;
          data.approval_status = prvData.approval_status;
        }
      } else {
        // already not approved
        console.log("already not approved");

        data.approval_required = prvData.approval_required;
        data.approval_status = prvData.approval_status;
      }
    }
    setData({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prvData, data.taxes, data.discounts, data.discounts]);
  const [showAttachment, setShowAttachment] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const [showSnaps, setShowSnaps] = useState(false);
  const existingItems = data.items || [];
  const uploadFile = async ({
    file,
    category,
    removeSelectedFile,
    setShowUpload,
  }: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => {
    try {
      if (!file || !category) {
        showToast({
          type: ToastType.warning,
          text: "Please select file and category",
        });
        return;
      }
      setLoading(true);
      const { name, type } = file;
      const create_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${data.id}/attachment`;
      const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.pocuremnt_attachments,
      };
      const res = await fetchGet(get_signed_urls + toQueryString(query));
      if (res.success) {
        const { signed_url, url } = res.data;
        console.log({ signed_url, url });

        //   const options = {
        //     method: "PUT",
        //     body: file,
        //     headers: {
        //       "Content-Type": type,
        //     },
        //   };
        const aws_res = await axios.put(signed_url, file, {
          headers: {
            "Content-Type": type,
          },
        });
        console.log({ aws_res });
        const attachment: AttachmentModel = {
          id: "",
          category,
          type: getFileType(type),
          url,
        };
        const create_res = await fetchPost(create_url, attachment);
        console.log({ create_res });
        if (create_res.success) {
          showToast({
            type: ToastType.success,
            text: create_res.message,
          });
          setData((o) => ({
            ...o,
            attachments: [...(o.attachments || []), create_res.data],
          }));

          removeSelectedFile();
          setShowUpload(false);
          setAttachmentKey(Math.random() * 1000);
        }
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };
  const deleteFile = async (att: AttachmentModel) => {
    try {
      if (!window.confirm("Are your sure to delete ?")) return;
      if (!att) {
        showToast({
          type: ToastType.warning,
          text: "Please select file",
        });
        return;
      }
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${data.id}/attachment/${att.id}`;

      const res = await fetchDelete(url);

      if (res.success) {
        showToast({
          type: ToastType.success,
          text: res.message,
        });
        setData((o) => ({
          ...o,
          attachments: [
            ...(o.attachments || []).filter((f) => f.id !== att.id),
          ],
        }));
        setAttachmentKey(Math.random() * 1000);
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };

  useEffect(() => {
    if (data.id && !project && data.project_id) {
      getProjectData(data.project_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getProjectData = async (id: string) => {
    setLoading(true);

    const res = await fetchGet(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
    );
    if (res.success) {
      setProject({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequestModel>({
    ...payment_request_default,
  });

  const handleCreatePaymentRequest = async () => {
    // validate

    if (paymentRequest.amount < 1 || !paymentRequest.due_date) {
      return showToast({
        type: ToastType.error,
        text: "Please enter all values!",
      });
    }
    const bal = data.total - data.paid;
    if (paymentRequest.amount > bal) {
      return showToast({
        type: ToastType.error,
        text: `Amount should not be greater than ${bal}`,
      });
    }
    paymentRequest.po_no = data.po_no;
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/payment_request`;
    setLoading(true);
    const res = await fetchPost(url, paymentRequest);
    if (res.success) {
      setShowCreatePaymentRequestModal(false);
      setPaymentRequest({ ...payment_request_default });
      showToast({ type: ToastType.success, text: res.message });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold">
            # {data.po_no}
          </span>
          <div className="flex gap-5">
            <button
              onClick={() => {
                getProjectData(data.project_id);
                setShowSucess(false);
              }}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Continue
            </button>
            <button
              onClick={() => {
                setPrvData({
                  ...po_default_value,
                  shipping_profile: {
                    ...default_mail_detail,
                    contact_name:
                      user?.first_name + " " + (user?.last_name || ""),
                    contact_mobile: user?.mobile || "",
                    contact_email: user?.email || "",
                  },
                });
                setData({
                  ...po_default_value,
                  shipping_profile: {
                    ...default_mail_detail,
                    contact_name:
                      user?.first_name + " " + (user?.last_name || ""),
                    contact_mobile: user?.mobile || "",
                    contact_email: user?.email || "",
                  },
                });

                getProjectData(data.project_id);
                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <>
      {showCreatePaymentRequestModal && (
        <ModalDialog
          show={showCreatePaymentRequestModal}
          title={"Create Payment Request"}
          onClose={function (): void {
            setPaymentRequest({ ...payment_request_default });
            setShowCreatePaymentRequestModal(false);
          }}
        >
          <div className="flex flex-col gap-3 w-96 h-max p-5">
            <div className="flex flex-col gap-1">
              <label htmlFor="" className="text-xs text-gray-500">
                Enter Amount
              </label>
              <input
                type="number"
                className="border px-2 py-1 rounded-md"
                placeholder="ex : 10000"
                value={paymentRequest.amount}
                onChange={(e) =>
                  setPaymentRequest((pv) => ({
                    ...pv,
                    amount: Number(e.target.value),
                  }))
                }
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="" className="text-xs text-gray-500">
                Select due date
              </label>
              <input
                type="date"
                className="border px-2 py-1 rounded-md"
                value={moment(paymentRequest.due_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const date = UTCToLocalDate(e.target.value);
                  date?.setHours(0, 0, 0, 0);
                  setPaymentRequest((pv) => ({
                    ...pv,
                    due_date: date!,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="" className="text-xs text-gray-500">
                Enter Description
              </label>
              <textarea
                className="border px-2 py-1 rounded-md"
                placeholder="note"
                value={paymentRequest.description}
                onChange={(e) =>
                  setPaymentRequest((pv) => ({
                    ...pv,
                    description: e.target.value,
                  }))
                }
              />
            </div>
            <button
              onClick={handleCreatePaymentRequest}
              className="bg-myPrimaryColor px-2 py-1 rounded-md text-white ml-auto"
            >
              Create
            </button>
          </div>
        </ModalDialog>
      )}
      {showSnaps && data.id && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Purchase order prints",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: <PoSnapSection po_no={data.po_no} />,
          }}
          onClose={function (): void {
            setShowSnaps(false);
          }}
        ></DrawerWidget>
      )}
      {showAttachment && data.id && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attchments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <AttachmentSection
                key={attachmentKey}
                attachments={data.attachments}
                onSubmit={uploadFile}
                onDelete={deleteFile}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(false);
          }}
        ></DrawerWidget>
      )}

      <div className="relative bg-myBgcolor h-screen px-2 md:px-5  overflow-auto">
        <div className="flex items-center justify-between py-2 sticky top-0 bg-myBgcolor">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Purchase Order <span className="text-sm">#{data.po_no}</span>
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div>
        </div>
        {data.approval_status === po_approval_status.rejected &&
          data.commets.length > 0 && (
            <p>
              <b>Reason of rejection :</b>
              {data.commets[data.commets.length - 1].comment}
            </p>
          )}
        <div className="bg-white rounded-lg px-3 py-5 my-2 grid grid-cols-1 md:grid-cols-4 ">
          <div className="col-span-2 flex flex-col gap-2">
            <div className="">
              <label htmlFor="" className="text-sm font-semibold  ">
                Project <RequiredMark />
              </label>{" "}
              <ProjectSearchField
                disabled={disabled}
                placeholder="Select Project"
                value={project}
                onSelect={(d) => {
                  setProject(d);
                }}
              />
            </div>

            <div className=" text-sm mt-2">
              <div className="grid grid-cols-4">
                <h5>Project name</h5>
                <p className="col-span-3">:{project?.name}</p>
              </div>
              <div className="grid grid-cols-4">
                <h5>Description</h5>
                <p className="col-span-3">:{project?.description}</p>
              </div>
              <hr />
              <div className="grid grid-cols-4">
                <h5>Amount :</h5>
                <p className="col-span-3  text-right">
                  {currencyFormat(project?.amount || 0)}
                </p>
              </div>
              <div className="grid grid-cols-4">
                <h5>Spent :</h5>
                <p className="col-span-3  text-right">
                  {currencyFormat(project?.spent || 0)}
                </p>
              </div>
              <hr />
              <div className="grid grid-cols-4">
                <h5>Balance : </h5>
                <p className="col-span-3 text-right">
                  {currencyFormat(
                    (project?.amount || 0) - (project?.spent || 0)
                  )}
                </p>
              </div>

              <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
                <Icon path={mdiInformationOutline} size={0.8}></Icon>Note : this
                balance is real-time ,Eg: if you create a po on x date and
                balance is y then if you open the same po on z date balance may
                be v.
              </div>
            </div>
          </div>
          {data.po_no &&
            data.approval_level === data.current_approval_level &&
            (data.current_approval_count ?? 0) >=
              (data.current_approval_required_count ?? 0) && (
              <div className="flex items-start col-span-2">
                <button
                  className="ml-auto bg-myPrimaryColor px-3 py-1 text-sm rounded-md text-white"
                  onClick={() => {
                    setPaymentRequest((o) => ({
                      ...o,
                      amount: data.total - (data?.paid || 0),
                    }));
                    setShowCreatePaymentRequestModal(true);
                  }}
                >
                  Create Payment Request
                </button>
              </div>
            )}
        </div>
        <div className="bg-white rounded-lg px-3 py-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 ">
            <div className="">
              <div className=" grid grid-cols-1 md:grid-cols-2 text-sm mt-2 gap-2">
                {" "}
                <label htmlFor="" className="text-sm font-semibold  ">
                  Vendor <RequiredMark />
                </label>{" "}
                <PartySearchField
                  show_add={true}
                  disabled={disabled}
                  placeholder="Vendor"
                  value={data.party ? data.party : undefined}
                  onSelect={(d) => {
                    setData((o) => {
                      const t = {
                        ...o,
                        party: d,

                        party_mail_detail: d.mail_detail,
                      };

                      if (d.currency) {
                        t.vendor_currency = d.currency;
                      }
                      return t;
                    });
                  }}
                />
                <div className="">Address</div>
                <div className="">
                  {data.party_mail_detail.address.city},
                  {data.party_mail_detail.address.country}-
                  {data.party_mail_detail.address.pincode},
                </div>
                <div className="">Contact person</div>
                <div className="">
                  {" "}
                  <input
                    disabled={disabled}
                    type="text"
                    placeholder="Enter name"
                    value={data.party_mail_detail?.contact_name}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        party_mail_detail: {
                          ...o.party_mail_detail,
                          contact_name: e.target.value,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact phone</div>
                <div className="">
                  {" "}
                  <input
                    disabled={disabled}
                    type="tel"
                    placeholder="Enter contact number"
                    value={data.party_mail_detail?.contact_mobile}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        party_mail_detail: {
                          ...o.party_mail_detail,
                          contact_mobile: e.target.value,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact email</div>
                <div className="">
                  {" "}
                  <input
                    disabled={disabled}
                    type="email"
                    placeholder="Enter email"
                    value={data.party_mail_detail?.contact_email}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        party_mail_detail: {
                          ...o.party_mail_detail,
                          contact_email: e.target.value,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded  w-full"
                  />
                </div>
                <div className="">Currency</div>
                <div className="">
                  <select
                    disabled={disabled}
                    required
                    className="p-1 focus:outline-none border rounded  w-full"
                    id="currency"
                    name="currency"
                    value={
                      data.vendor_currency &&
                      JSON.stringify(data.vendor_currency)
                    }
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        vendor_currency: JSON.parse(e.target.value),
                      }));
                    }}
                  >
                    <option value="" hidden>
                      select
                    </option>
                    {Object.values(currencies).map((currency: any) => (
                      <option value={JSON.stringify(currency)}>
                        {currency.formal_name}({currency.symbol})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">Currency Exchange rate({"\u20b9"})</div>
                <div className="">
                  <input
                    disabled={disabled}
                    type="number"
                    placeholder="Exchange rate of inr"
                    value={data.vendor_currency_to_inr}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        vendor_currency_to_inr: Number(e.target.value),
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded  w-full"
                  />
                  <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
                    <Icon path={mdiInformationOutline} size={0.8}></Icon>
                    INR Per {data.vendor_currency.short_name} Eg: INR 70 = USD 1
                    then enter 70
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="">
              <div className=" grid  grid-cols-1 md:grid-cols-2 text-sm mt-2 gap-1">
                {" "}
                <label htmlFor="" className="text-sm font-semibold  ">
                  Shipping profile
                </label>
                <WarehouseSearchField
                  type={WAREHOUSE_TYPE.warehouse}
                  disabled={disabled}
                  placeholder="Warehouse"
                  value={data.warehouse.id ? data.warehouse : undefined}
                  onSelect={(d) => {
                    setData((o) => ({
                      ...o,
                      warehouse: d,
                      warehouse_id: d.id,
                      shipping_profile: {
                        ...o.shipping_profile,
                        address: d.address!,
                      },
                    }));
                  }}
                />
                <div className="">Address</div>
                <div className="">
                  {data.shipping_profile?.address.city},
                  {data.shipping_profile?.address.country}-
                  {data.shipping_profile?.address.pincode},
                </div>
                <div className="">Contact person</div>
                <div className="">
                  {" "}
                  <input
                    disabled={disabled}
                    type="text"
                    placeholder="Enter name"
                    value={data.shipping_profile?.contact_name}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_name: e.target.value,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact phone</div>
                <div className="">
                  {" "}
                  <input
                    disabled={disabled}
                    type="tel"
                    placeholder="Enter contact number"
                    value={data.shipping_profile?.contact_mobile}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_mobile: e.target.value,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded w-full "
                  />
                </div>
                <div className="">Contact email</div>
                <div className="">
                  {" "}
                  <input
                    disabled={disabled}
                    type="email"
                    placeholder="Enter email"
                    value={data.shipping_profile?.contact_email}
                    onChange={(e) => {
                      setData((o) => ({
                        ...o,
                        shipping_profile: {
                          ...o.shipping_profile,
                          contact_email: e.target.value,
                        },
                      }));
                    }}
                    className="p-1 focus:outline-none border rounded  w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <h3 className="text-sm font-bold">Items</h3>
          <div
            className="bg-white rounded flex flex-col gap-1 border   "
            // style={{ height: "75vh" }}
          >
            <div className="grid grid-cols-9 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t  ">
              <div className="col-span-2">Particular</div>
              <div className="text-center">HSN/SAC</div>
              <div className="text-center">qty</div>
              <div className="text-center">Price</div>
              <div className="text-right">Amount</div>
              <div className="text-center" title="Received qty">
                Received qty
              </div>
              <div className="text-center" title="Returned qty">
                Returned qty
              </div>
              <div className="text-right"> </div>
            </div>
            <div className="flex flex-col gap-1 px-1  text-sm overflow-auto h-72">
              {data.items.map((item, i) => {
                return (
                  <PoItemRow
                    currency={data.vendor_currency}
                    disabled={disabled || false}
                    key={item.id}
                    data={item}
                    onDelete={(d) => {
                      setData((o) => ({
                        ...o,
                        items: [...(o.items || [])].filter(
                          (i) => i.id !== d.id
                        ),
                      }));
                    }}
                    onSubmit={function (data: PoItem): void {
                      setData((o) => ({
                        ...o,
                        items: [
                          ...(o.items || []).map((it) => {
                            if (it.id === data.id) return data;
                            return it;
                          }),
                        ],
                      }));
                    }}
                    edit={false}
                    existingItems={existingItems}
                  />
                );
              })}
              {!disabled && (
                <PoItemRow
                  currency={data.vendor_currency}
                  key={addNewId}
                  onSubmit={function (data: PoItem): void {
                    data.id = (Math.random() * 1000).toString();
                    setData((o) => ({
                      ...o,
                      items: [...(o.items || []), data],
                    }));
                    setAddNewId(Math.random() * 1000);
                  }}
                  edit={true}
                  disabled={false}
                  existingItems={existingItems}
                />
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 my-2">
          <div className="">
            <h3 className="text-sm font-bold">Taxes</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              {data.taxes.map((tax, i) => (
                <TaxEntryRow
                  currency={data.vendor_currency}
                  disabled={disabled || false}
                  key={tax.id + `${tax.amount}`}
                  entry={tax}
                  items={[...(data.items || [])]}
                  onDelete={(d) => {
                    d.type = accountTxnType.debitor;
                    setData((o) => {
                      const taxes = [...(o.taxes || [])].filter(
                        (i) => i.id !== d.id
                      );
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total =
                        o.subtotal +
                        (tax ?? 0) +
                        (o.extra ?? 0) -
                        (o.discount ?? 0);
                      return {
                        ...o,
                        taxes,
                        tax,
                        total,
                      };
                    });
                  }}
                  onSubmit={function (d: LedgerEntryModel): void {
                    setData((o) => {
                      const taxes = [
                        ...(o.taxes || [[]]).map((t) => {
                          if (t.id === d.id) {
                            return { ...d };
                          }
                          return { ...t };
                        }),
                      ].map((t, i) => {
                        // if (data.entry_type === voucherEntryType.Invoice)
                        if (t.ledger?.tax_detail?.type === tax_type.gst) {
                          let tax = getItemTaxAmount({
                            entry_type: "Invoice",
                            items: data.items || [],
                            ledgerTaxDetail: t.ledger!.tax_detail!,
                            extras: data.extras,
                          });
                          if (typeof tax == "number") {
                            t.amount = tax;
                          }
                        }
                        return { ...t };
                      });
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total =
                        o.subtotal +
                        (tax ?? 0) +
                        (o.extra ?? 0) -
                        (o.discount ?? 0);
                      return {
                        ...o,
                        taxes,
                        tax,
                        total,
                      };
                    });
                    setAddNewTaxId(Math.random() * 1000);
                  }}
                  edit={false}
                />
              ))}
              {!disabled && (
                <TaxEntryRow
                  currency={data.vendor_currency}
                  key={addNewTaxId}
                  items={[...(data.items || [])]}
                  onSubmit={function (d: LedgerEntryModel): void {
                    d.id = (Math.random() * 1000).toString();
                    setData((o) => {
                      d.type = accountTxnType.debitor;
                      const taxes = [...(o.taxes || [[]]), d].map((t, i) => {
                        if (t.ledger?.tax_detail?.type === tax_type.gst) {
                          let tax = getItemTaxAmount({
                            entry_type: "Invoice",
                            items: data.items || [],
                            ledgerTaxDetail: t.ledger!.tax_detail!,
                            extras: data.extras,
                          });
                          console.log({ tax });

                          if (typeof tax == "number") {
                            t.amount = tax;
                          }
                        }
                        return { ...t };
                      });
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total =
                        o.subtotal +
                        (tax ?? 0) +
                        (o.extra ?? 0) -
                        (o.discount ?? 0);
                      return {
                        ...o,
                        taxes,
                        tax,
                        total,
                      };
                    });
                    setAddNewTaxId(Math.random() * 100);
                  }}
                  edit={true}
                  disabled={disabled || false}
                />
              )}
            </div>
          </div>
          <div className="">
            <h3 className="text-sm font-bold">Discounts</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    disabled={disabled}
                    group={[account_group.indirect_income.name]}
                    value={
                      data.discounts[0] ? data.discounts[0].ledger : undefined
                    }
                    onSelect={function (data: LedgerModel): void {
                      setData((ol) => {
                        return {
                          ...ol,

                          discounts: [
                            {
                              ...(ol.discounts[0] || ledgerEntryDefaultValue),
                              ledger: data,
                              ledger_id: data.id,
                              type: accountTxnType.creditor,
                            },
                          ],
                        };
                      });
                    }}
                  />
                </div>
                <div className="grid grid-cols-4  items-center">
                  <div className="text-right">
                    {data.vendor_currency.symbol}
                  </div>{" "}
                  <input
                    disabled={disabled || !data.discounts[0]}
                    value={data.discounts[0]?.amount}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val || numberRegEx.test(val)) {
                        const value = parseFloat(val || "0");
                        setData((ol) => ({
                          ...ol,
                          discounts: [
                            {
                              ...(ol.discounts[0] || ledgerEntryDefaultValue),
                              amount: value,
                              type: accountTxnType.creditor,
                            },
                          ],
                          discount: value,
                          total:
                            ol.subtotal +
                            (ol.tax || 0) +
                            (ol.extra || 0) -
                            value,
                        }));
                      }
                    }}
                    placeholder=""
                    type="text"
                    className="text-right  p-1 focus:outline-none border rounded  w-full col-span-3"
                  />
                </div>
              </div>
            </div>
            {/* <div className="text-sm flex justify-end">
              <div className="flex flex-col gap-2">
                <div className=" flex gap-5 justify-between">
                  <div className="">Sub total:</div>
                  <div className="">{data.subtotal || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Tax:</div>
                  <div className="">{data.tax || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Discount:</div>
                  <div className="">{data.discount || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between font-bold">
                  <div className="">Total:</div>
                  <div className="">{data.total || 0}</div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="">
            <h3 className="text-sm font-bold">Extra charges</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    disabled={disabled}
                    group={[
                      account_group.indirect_expense.name,
                      account_group.misc_expense.name,
                    ]}
                    value={data.extras[0] ? data.extras[0].ledger : undefined}
                    onSelect={function (data: LedgerModel): void {
                      setData((ol) => {
                        return {
                          ...ol,

                          extras: [
                            {
                              ...(ol.extras[0] || ledgerEntryDefaultValue),
                              ledger: data,
                              ledger_id: data.id,
                              type: accountTxnType.debitor,
                            },
                          ],
                        };
                      });
                    }}
                  />
                </div>
                <div className="grid grid-cols-4  items-center">
                  <div className="text-right">
                    {data.vendor_currency.symbol}
                  </div>{" "}
                  <input
                    disabled={disabled || !data.extras[0]}
                    value={data.extras[0]?.amount}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val || numberRegEx.test(val)) {
                        const value = parseFloat(val || "0");
                        setData((ol) => ({
                          ...ol,
                          extras: [
                            {
                              ...(ol.extras[0] || ledgerEntryDefaultValue),
                              amount: value,
                              type: accountTxnType.creditor,
                            },
                          ],
                          extra: value,
                          total:
                            ol.subtotal +
                            value +
                            (ol.tax || 0) -
                            (ol.discount || 0),
                        }));
                      }
                    }}
                    placeholder=""
                    type="text"
                    className="text-right  p-1 focus:outline-none border rounded  w-full col-span-3"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
              <Icon path={mdiInformationOutline} size={0.8}></Icon>Note : Tax
              applicable on Extra charges.
            </div>
            {/* <div className="text-sm flex justify-end">
              <div className="flex flex-col gap-2">
                <div className=" flex gap-5 justify-between">
                  <div className="">Sub total:</div>
                  <div className="">{data.subtotal || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Tax:</div>
                  <div className="">{data.tax || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between">
                  <div className="">Discount:</div>
                  <div className="">{data.discount || 0}</div>
                </div>
                <div className=" flex gap-5 justify-between font-bold">
                  <div className="">Total:</div>
                  <div className="">{data.total || 0}</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 md:divide-x-2  text-sm  bg-white rounded-lg p-3  ">
          <div className=" flex flex-col gap-1 md:pr-5 ">
            <div className="grid grid-cols-1  md:grid-cols-4   dark: items-center">
              <label htmlFor="valid_till_date">Valid till Date</label>
              <input
                // disabled={disabled}
                value={
                  data.valid_till_date
                    ? moment(data.valid_till_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    valid_till_date: UTCToLocalDate(e.target.value)!,
                  }));
                }}
                type="date"
                name=""
                id="valid_till_date"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></input>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4   dark: items-center">
              <label htmlFor="delivery_date">Delivery Date</label>
              <input
                // disabled={disabled}
                value={
                  data.delivery_date
                    ? moment(data.delivery_date).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    delivery_date: UTCToLocalDate(e.target.value)!,
                  }));
                }}
                type="date"
                name=""
                id="delivery_date"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></input>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4   items-center">
              <label htmlFor="delivery_method">Delivery Method</label>
              <select
                // disabled={disabled}
                value={data.shipping_method}
                onChange={(e) => {
                  setData((o) => ({ ...o, shipping_method: e.target.value }));
                }}
                name=""
                id="delivery_method"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              >
                <option value="" hidden>
                  select{" "}
                </option>
                {["Road", "Ship", "Airway"].map((m) => (
                  <option value={m} key={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-start">
              <label htmlFor="ship_term">Delivery terms</label>
              <textarea
                disabled={disabled}
                rows={3}
                value={data.ship_term}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    ship_term: e.target.value,
                  }));
                }}
                name=""
                id="ship_term"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></textarea>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-start">
              <label htmlFor="payment_term">Payment terms</label>
              <textarea
                // disabled={disabled}
                rows={3}
                value={data.payment_term}
                onChange={(e) => {
                  setData((o) => ({
                    ...o,
                    payment_term: e.target.value,
                  }));
                }}
                name=""
                id="payment_term"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></textarea>
            </div>
          </div>
          <div className="flex flex-col gap-1 md:px-5">
            <div className="grid grid-cols-1  md:grid-cols-4  items-center">
              <label htmlFor="payment_mode">Payment Mode</label>
              <select
                value={data.payment_mode}
                onChange={(e) => {
                  setData((o) => ({ ...o, payment_mode: e.target.value }));
                }}
                name=""
                id="payment_mode"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              >
                <option value="" hidden>
                  select{" "}
                </option>
                {Object.values(PaymentMode).map((m) => (
                  <option value={m} key={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4  items-center">
              <label htmlFor="status">Status</label>
              <select
                value={data.status}
                onChange={(e) => {
                  setData((o) => ({ ...o, status: e.target.value }));
                }}
                name=""
                id="status"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              >
                <option value="" hidden>
                  select{" "}
                </option>
                {Object.values(po_status).map((m) => (
                  <option value={m} key={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-4 items-center">
              <label htmlFor="approval_status">Approval Level</label>
              <div className="p-1 focus:outline-none border rounded w-full  col-span-3">
                {data.approval_level}
              </div>
            </div>
            {/* <div className="grid grid-cols-1  md:grid-cols-4">
              <label htmlFor="approved_by">Approved by</label>
              <input
                disabled
                value={
                  data.approval_required
                    ? data.approved_by_name || "--"
                    : "Not required "
                }
                name=""
                id="approved_by"
                type="text"
                className="p-1 focus:outline-none border rounded w-full  col-span-3"
              ></input>
            </div> */}
            {/* <hr className="my-5" />
            <div className="flex ">
              <button
                onClick={() => setShowAttachment(true)}
                className=" border  rounded p-1 flex justify-center items-center "
              >
                <Icon
                  path={mdiAttachment}
                  size={0.8}
                  className="hover:scale-105 transform"
                ></Icon>
                Attachments
              </button>
            </div> */}
          </div>
          <div className=" flex flex-col gap-1 md:pl-5 ">
            <div className="flex justify-between">
              <div className="">Subtotal</div>
              <div className="">
                {currencyFormat(data.subtotal, data.vendor_currency.short_name)}
              </div>
            </div>{" "}
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="extra_desc">Extra </label>{" "}
              </div>

              <div className="">
                + {currencyFormat(data.extra, data.vendor_currency.short_name)}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="tax_desc">Tax </label>{" "}
                {/* <input
                  placeholder="description"
                  type="text"
                  value={data.tax.desc}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      tax: { ...o.tax, desc: e.target.value },
                    }));
                  }}
                  name=""
                  id="tax_desc"
                  className=" focus:outline-none  bg-transparent   "
                ></input> */}
              </div>
              {/* <input
                disabled
                placeholder={"\u20b9 0"}
                type="text"
                value={data.tax}
                onChange={(e) => {
                  let v = 0;

                  if (e.target.value && !isNaN(e.target.value as any)) {
                    v = parseFloat(e.target.value);
                  }
                  setData((o) => ({
                    ...o,
                    tax: v,
                  }));
                }}
                name=""
                id=" "
                className="p-1 focus:outline-none border rounded  w-32 text-right"
              ></input> */}
              <div className="">
                + {currencyFormat(data.tax, data.vendor_currency.short_name)}
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="flex  gap-2 items-end ">
                <label htmlFor="discount_desc">Discount </label>{" "}
                {/* <input
                  placeholder=" description"
                  type="text"
                  value={data.discount.desc}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      discount: { ...o.discount, desc: e.target.value },
                    }));
                  }}
                  name=""
                  id="discount_desc"
                  className=" focus:outline-none  bg-transparent   "
                ></input> */}
              </div>
              {/* <input
                disabled
                placeholder={"\u20b9 0"}
                type="text"
                value={data.discount}
                onChange={(e) => {
                  let v = 0;
                  if (e.target.value && !isNaN(e.target.value as any)) {
                    v = parseFloat(e.target.value);
                  }
                  setData((o) => ({
                    ...o,
                    discount: v,
                  }));
                }}
                name=""
                id=" "
                className="p-1 focus:outline-none border rounded  w-32 text-right"
              ></input> */}
              <div className="">
                -{" "}
                {currencyFormat(data.discount, data.vendor_currency.short_name)}
              </div>
            </div>
            <hr />
            <div className="flex justify-between font-bold ">
              <p>Total</p>{" "}
              <div className="">
                {currencyFormat(data.total, data.vendor_currency.short_name)}
              </div>
            </div>
            <hr />
            <div className="flex justify-between font-bold ">
              <p>Paid</p>{" "}
              <div className="">
                {currencyFormat(data.paid, data.vendor_currency.short_name)}
              </div>
            </div>
            <hr />
            <div className="flex justify-between font-bold ">
              <p>Balance</p>{" "}
              <div className="">
                {currencyFormat(
                  data.total - (data?.paid || 0),
                  data.vendor_currency.short_name
                )}
              </div>
            </div>
            {data.vendor_currency.short_name !==
              currencies.rupee.short_name && (
              <div className="flex justify-between font-bold ">
                <p>Total in INR</p>{" "}
                <div className="">
                  {currencyFormat(data.total * data.vendor_currency_to_inr)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="sticky bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5 border">
          <h3 className="text-sm font-semibold">Approval status</h3>
          <PoCreateBottomSheetWidget data={data} onSubmit={submit} />
        </div>
      </div>
      {id && (
        <div className="fixed flex flex-col gap-2 bottom-3 right-3">
          {data.approval_level === data.current_approval_level &&
            (data.current_approval_count ?? 0) >=
              (data.current_approval_required_count ?? 0) && (
              <button
                title="print"
                // to={`${window.document.location.pathname}/comment`}
                onClick={() => setShowSnaps(true)}
                className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-green-400 text-white   shadow cursor-pointer flex items-center justify-center"
              >
                <Icon
                  path={mdiPrinter}
                  size={1}
                  className="hover:scale-105 transform  "
                ></Icon>
              </button>
            )}
          <button
            title="Attchments"
            // to={`${window.document.location.pathname}/comment`}
            onClick={() => setShowAttachment(true)}
            className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-green-400 text-white   shadow cursor-pointer flex items-center justify-center"
          >
            <Icon
              path={mdiAttachment}
              size={1}
              className="hover:scale-105 transform -rotate-45"
            ></Icon>
          </button>
        </div>
      )}
      {loading && <LoadingWidget />}
    </>
  );
};

export default PurchaseOrderCreateScreen;
