import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { debounce } from "lodash";
import { mdiClose, mdiFilterMenu, mdiRefresh } from "@mdi/js";
import moment from "moment";
import TableView from "../../../../procurement/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  toQueryString,
} from "../../../../service/api_client";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import LoadingWidget from "../../../../context_providers/modal/loader";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { urls } from "../../../../utils/urls";
import { StockJournalModel } from "../../models/inventory_voucher";

interface DestinationStockTransferJournalListScreenProps {
  records: StockJournalModel[];
  onClick: (id: string) => void;
  sort_by: string;
  sort_order: number;
  pages: number;
  options: []; // Replace with actual type
  handleSort: (data: { sort: string; sort_order: number }) => void;
  handlePageChange: (page: number) => void;
  handleStatusFilterChange: (selectedOptions: string[]) => void;
}

interface StockListFilter {
  page: number;
  count: number;
  search?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
  voucherStatus: string[],
}

const defaultFilter = (): StockListFilter => {
  const now = new Date();
  const start_date = new Date(new Date(now).setDate(now.getDate() - 30));
  const end_date = now;
  return {
    page: 1,
    count: 100,
    fromDate: start_date,
    toDate: end_date,
    voucherStatus: [],
  };
};

const DestinationStockTransferJournalListScreen: FunctionComponent<
  DestinationStockTransferJournalListScreenProps
> = ({
  records,
  onClick,
  sort_by,
  sort_order,
  pages,
  // Ensure this is correctly typed
  handleSort,
  handlePageChange,
}) => {
  const navigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/source_transfer`;
  const dest_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/destination_transfer`;
  const { showToast } = useToast();
  const [page, setPage] = useState(1);
  const [count] = useState(50);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<StockJournalModel[]>([]);
  const [search, ] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filter, setFilter] = useState<StockListFilter>(defaultFilter());
  const [options, ] = useState<string[]>(["Completed", "Pending"]);

  useEffect(() => {
    const now = new Date();
    let from = new Date(new Date(now).setHours(0, 0, 0, 0));
    from = new Date(from.setDate(1));
    let to = new Date(new Date(now).setHours(23, 59, 59, 999));
    to = new Date(to.setMonth(now.getMonth() + 1));
    to = new Date(to.setDate(0));
    setFromDate(from);
    setToDate(to);
  }, []);

  // Format ISO date to YYYY-MM-DD
  const formatISODate = (isoDateString: any): any => {
    const dateObject = new Date(isoDateString);
    return dateObject.toISOString().slice(0, 10);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(
    debounce((prop: StockListFilter) => {
      if (prop) fetchData(prop);
      else setData([]);
    }, 300),
    []
  );
  useEffect(() => {
    debouncedHandler(filter);
  }, [filter, debouncedHandler]);

  const fetchData = async (prop: StockListFilter) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      const res1 = await fetchGet(dest_url + toQueryString(prop));
      res1.data.data = res.data.data;
      // console.log(res1.data.data);
      if (res1.success) {
        setData(res1.data.data);
        if (res1.data.metadata) {
          setTotal(res1.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    } finally {
      setLoading(false);
    }
  };

  // const onSelect = (d: StockJournalModel) => {
  //   navigation.push(urls.stock_transfer_destination + "/update/" + d.id);
  // };

  const onSelect = (d: StockJournalModel) => {
    if (d.status !== "Returned") {
      navigation.push(urls.stock_transfer_destination + "/update/" + d.id);
    } else {
      showToast({
        type: ToastType.error,
        text: "Return Voucher can't be editable.",
      });
    }
    // navigation.push(urls.stock_transfer_destination + "/update/" + d.id);
  };

  const onDelete = async (d: StockJournalModel) => {
    const promptText = window.prompt("Type 'delete' to confirm.");
    if (!promptText || promptText.trim() !== "delete") {
      return;
    }
    setLoading(true);
    const res = await fetchDelete(url + "/" + d.id);
    if (res.success) {
      await fetchData({
        page, count, search,
        voucherStatus: []
      });
      showToast({ type: ToastType.success, text: res.message });
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };

  const handleStatusFilterChange = (selectedOptions: string[]) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      voucherStatus: selectedOptions,
    }));
  };

  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
            Destination Stock Transfer
            <button
              title="Refersh"
              onClick={() =>
                debouncedHandler({
                  page, count, search, fromDate, toDate,
                  voucherStatus: []
                })
              }
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            <div className="text-sm flex items-center gap-2">
              <div className="flex items-center  gap-1">
                <label htmlFor="fromDate" className="">
                  From
                </label>
                <input
                  type="date"
                  id="fromDate"
                  className="p-1 rounded focus:outline-none"
                  value={
                    filter.fromDate
                      ? moment(filter.fromDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = UTCToLocalDate(e.target.value);
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      fromDate: selectedDate,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center  gap-1">
                <label htmlFor="toDate" className="">
                  To
                </label>
                <input
                  type="date"
                  id="toDate"
                  className="p-1 rounded focus:outline-none "
                  value={
                    filter.toDate
                      ? moment(filter.toDate).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const selectedDate = UTCToLocalDate(e.target.value);
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      toDate: selectedDate,
                    }));
                  }}
                />
              </div>
              {(filter.fromDate || filter.toDate) && (
                <button
                  title="Clear dates"
                  onClick={() =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      fromDate: undefined,
                      toDate: undefined,
                    }))
                  }
                  className="flex gap-1 items-center cursor-pointer rounded p-1 border r hover:scale-105 duration-200     transform"
                >
                  <Icon path={mdiClose} size={0.7}></Icon>
                </button>
              )}
            </div>
            <SearchFieldWidget
              defaulValue={filter.search}
              onClear={() => {
                setFilter((o) => ({ ...o, search: "", page: 1 }));
              }}
              onSubmit={(val) => {
                setFilter((o) => ({
                  ...o,
                  search: val,
                  page: 1,
                }));
              }}
            />
            {/* <div
                className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                onClick={() =>
                  navigation.push(urls.stock_transfer_voucher + "/create")
                }
              >
                <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
              </div> */}
          </div>
        </section>{" "}
        <TableView
          headers={[
            {
              key: "date",
              title: "Date",
              type: FieldType.date,
              onClick: (d) => {},
            },
            {
              key: "voucher_id",
              title: "Tracking Ref",
              type: FieldType.long_string,
            },
            {
              colspan: 4,
              key: "item",
              title: "Items",
              type: FieldType.long_string,
            },
            {
              key: "warehouse",
              title: "Warehouse",
              type: FieldType.string,
            },
            // {
            //   key: "voucher",
            //   title: "Voucher type",
            //   type: FieldType.string,
            //   icon: mdiFilterMenu,
            //   options: options, // Ensure options is correctly passed here
            //   onChange: handleVoucherTypeFilterChange,
            // },
            {
              key: "projectFrom",
              title: "Project From",
              type: FieldType.string,
            },
            {
              key: "projectTo",
              title: "Project To",
              type: FieldType.string,
            },
            {
              key: "inward",
              title: "Inward value",
              type: FieldType.string,
            },
            {
              key: "outward",
              title: "Outward value",
              type: FieldType.string,
            },
            {
              key: "sender",
              title: "Sender",
              type: FieldType.string,
            },
            {
              key: "receiver",
              title: "Receiver",
              type: FieldType.string,
            },
            {
              key: "status",
              title: "Status",
              type: FieldType.string,
              icon: mdiFilterMenu,
              options: options, // Ensure options is correctly passed here
              onChange: handleStatusFilterChange,
            },
          ]}
          rows={data.map((data, i) => ({
            data: data,
            values: [
              formatISODate(data.date),
              data.id,
              data.source?.[0]?.item?.name,
              data.receiver?.name,
              data.source?.[0]?.projectFrom,
              data.destination?.[0]?.projectTo?.name,
              data.destination_total,
              data.source_total,
              `${data.user?.first_name ?? ''} ${data.user?.last_name ?? ''}`.trim(),
              data.receiver?.spoc?.contact_name,
              data.status,
            ],
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={data.length}
          onPageChange={setPage}
          onClick={onSelect}
          // onEdit={onSelect}
          // onDelete={onDelete}
        />
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default DestinationStockTransferJournalListScreen;
